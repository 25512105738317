import { Feedback } from 'components/feature/feedback';
import { LastUpdated } from 'components/feature/last-updated.tsx';
import { PageTabs } from 'components/feature/page-tabs.tsx';
import { AccordionProvider, ExpandAllToggle } from 'components/markdown/accordion-card.tsx';
import { SpecHeader } from 'components/reference/spec-header.tsx';
import type { PageProps } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import { useSpecInfo } from 'hooks';
import { cn } from 'utils/cn.ts';
import { PageLayout } from '../layouts/page-layout';
import { isOverviewOrReferencePage } from '../layouts/root-layout.tsx';
import withAppInsights from '../utils/analytics/appinsights';

export type MarkdownPageContext = {
  title: string;
  description: string;
  body: string;
  isLargeContent: boolean;
  isGreyLayout: boolean;
  commitDate: number;
  image?: string;
};

const isPathChangelog = (pathname: string) =>
  !!pathname.match(/^\/changelog(\/.*)?\/?$/) && !pathname.match(/^\/changelog\/archive\/*$/);

export function MarkdownTemplate({ pageContext, location }: PageProps<null, MarkdownPageContext>) {
  const { isLargeContent, isGreyLayout = false, title, description, commitDate, body, image } = pageContext;
  const { pathname } = location;
  // Checking if content lives in api-products or domains but not the entry overview page for each
  const isGreyLayoutContents =
    pathname.match(/^\/domains\/[^\/]+\/[^\/]+\/?$/) ||
    pathname.match(/^\/api-products\/[^\/]+\/[^\/]+\/?$/) ||
    isPathChangelog(pathname);
  const specInfo = useSpecInfo(pathname.replace(/\/$/, ''));

  const specTitle = specInfo.title || title;
  const specDescription = specInfo.description || description;

  return (
    <div data-testid="markdown-template">
      <PageLayout
        location={location}
        isLargeContent={isLargeContent}
        isGreyLayout={!!isGreyLayoutContents || isGreyLayout}
        title={title}
        description={description}
        commitDate={commitDate}
      >
        {isGreyLayoutContents && (
          <div className="mb-10">
            <SpecHeader
              title={specTitle}
              description={specDescription}
              publicUrls={specInfo.publicUrls}
              isDeprecated={specInfo.isDeprecated}
              isOpenApi={specInfo.isOpenApi}
              version={specInfo.version}
              team={specInfo.team}
              tags={specInfo.tags}
              pagePath={pathname}
              image={image}
            />
            <PageTabs pagePath={pathname} tabs={specInfo.tabs} />
          </div>
        )}

        <AccordionProvider>
          {isPathChangelog(pathname) && (
            <div className="flex justify-end">
              <ExpandAllToggle />
            </div>
          )}
          <MDXRenderer>{body}</MDXRenderer>
        </AccordionProvider>

        {isOverviewOrReferencePage(location.pathname) && (
          <div className={cn('w-full px-20 py-[30px] rounded-lg', 'bg-white dark:bg-dark-800 mt-10')}>
            <Feedback />
            {commitDate && (
              <>
                <hr className="my-6 border-t border-neutral-300 opacity-60 dark:border-dark-500" />
                <LastUpdated commitDate={commitDate} />
              </>
            )}
          </div>
        )}
      </PageLayout>
    </div>
  );
}

export default withAppInsights(MarkdownTemplate);
